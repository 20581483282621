export function getType(type: string): string {
  switch (type) {
    case "output":
      return "output-tag";
    case "expressionType":
      return "exp-function-tag";
    case "translationType":
      return "translation-function-tag";
    case "translationOperationType":
      return "opr-function-tag";
    case "arithmetic-operator":
    case "logical-operator":
    case "relational-operator":
      return "operator-tag";
    case "text-function":
    case "math-function":
    case "general-function":
      return "function-tag";
    case "user-defined-variable":
      return "user-defined-variable-tag";
    case "metaoutput":
      return "metaoutput-tag";
    case "system-variable":
      return "system-variable-tag";
    default:
      return "function-tag";
  }
}

export function createHTMLSpanElement(item: any, hasChild: boolean, idCount: number, tooltip?: string | boolean): HTMLSpanElement {
  const span = document.createElement("span");
  span.setAttribute("name", item.name);
  span.setAttribute("type", item.type);
  span.setAttribute("id", `element-${idCount}`);
  span.setAttribute("function", item.function ?? "");

  if ((tooltip || tooltip === "") && item.tooltip !== undefined) {
    span.setAttribute("custom-tooltip", item.tooltip ?? "");
  }

  span.contentEditable = "false";
  span.innerText = hasChild ? `${item.name}(` : item.name;
  span.classList.add("tag");
  span.classList.add(getType(item.type));
  return span;
}

export function getCaretPosition(node: Node): number {
  let position = 0;
  const isSupported = typeof window.getSelection() !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();
    if (selection.rangeCount !== 0) {
      const range = window.getSelection()
        .getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(node);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      position = preCaretRange.toString().length;
    }
  }
  return position;
}

export function setCaretPosition(node: Node, id: string, offset?: number): void {
  const el = document.getElementById(id);
  const selection = window.getSelection();
  const range = document.createRange();
  selection.removeAllRanges();

  if (offset !== undefined) {
    range.setStart(node, offset);
  } else {
    range.selectNodeContents(el);
    range.setEndAfter(node);
    range.collapse(false);
  }

  selection.addRange(range);
  el.focus();
}
